.prompt-type-select section h3 {
  margin: 0;
}

.prompt-type-select section {
  border-bottom: 1px solid #ccc;
  padding: 20px 0 15px 0;
}

.prompt-type-select ion-item {
  --padding-start: 10px;
}

.prompt-type-select ion-item ion-label.sc-ion-label-ios-h {
  display: none;
}

.prompt-type-select ion-item ion-select {
  --padding-start: var(--ion-padding);
  width: 100%;
  max-width: 100%;
}

/* Icons */

.prompt-type-select ion-col.last {
  text-align: right;
}

.prompt-type-select .control-icon {
  position: relative;
  margin-bottom: 10px;
}

.prompt-type-select .control-icon ion-badge {
  position: absolute;
  bottom: -3px;
  right: -3px;
  padding: 0px;
  line-height: 1.2em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 2em;
}

.prompt-type-select .control-icon-camera ion-badge {
  bottom: 0;
}

/* Coloring of Select Options */
/* This improves usability by providing feedback, but longer term we will want a better way to convey this info, since all of the colors can be overwhelming */
.select-option-color-success .alert-radio-label,
.select-option-color-success .alert-checkbox-label,
[aria-checked="true"].select-option-color-success .alert-radio-label,
ion-select.selected-option-color-success::part(text) {
  --ion-text-color: var(--ion-color-success);
  color: var(--ion-color-success) !important;
}

.select-option-color-warning .alert-radio-label,
.select-option-color-warning .alert-checkbox-label,
[aria-checked="true"].select-option-color-warning .alert-radio-label,
ion-select.selected-option-color-warning::part(text) {
  --ion-text-color: var(--ion-color-warning);
  color: var(--ion-color-warning) !important;
}

.select-option-color-danger .alert-radio-label,
.select-option-color-danger .alert-checkbox-label,
[aria-checked="true"].select-option-color-danger .alert-radio-label,
ion-select.selected-option-color-danger::part(text) {
  --ion-text-color: var(--ion-color-danger);
  color: var(--ion-color-danger) !important;
}

.prompt-type-select ion-select {
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 5px;
}
