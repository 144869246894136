.prompt-type-radio ion-col.last {
  text-align: right;
}

.prompt-type-radio ion-radio-group {
  margin-top: 12px;
  display: flex;
  justify-content: space-evenly;
}

.prompt-type-radio ion-radio-group ion-label {
  text-align: center;
}

.prompt-type-radio ion-radio-group ion-row {
  gap: 12px 0;
}

.prompt-type-radio ion-radio-group ion-col {
  display: flex;
  justify-content: center;
}

.prompt-type-radio ion-radio-group ion-col {
  display: flex;
  justify-content: center;
}

.prompt-type-radio ion-radio {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.prompt-type-radio ion-radio .radio-icon {
  margin-left: 1px;
}

.prompt-type-radio .control-icon {
  position: relative;
  margin-bottom: 10px;
}

.prompt-type-radio .control-icon ion-badge {
  position: absolute;
  bottom: -3px;
  right: -3px;
  padding: 0px;
  line-height: 1.2em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 2em;
}

.prompt-type-radio .control-icon-camera ion-badge {
  bottom: 0;
}

.prompt-type-radio section h3 {
  margin: 0;
}

.prompt-type-radio section {
  border-bottom: 1px solid #ccc;
  padding: 20px 0 15px 0;
}

.prompt-type-radio ion-textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 7px;
  margin-top: 12px;
}
