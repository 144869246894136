.photo-progress {
  position: sticky;
  top: 1.5rem;
  padding: 0 0.5rem 0.25rem;
  font-size: 14px;
  z-index: 100;
}

.photo-progress-row {
  padding: 0;
  display: flex;
  align-items: center;
}

.photo-progress-hide {
  margin: 0;
}
