.prompt-type-textarea ion-col.last {
  text-align: right;
}

.prompt-type-textarea .control-icon {
  position: relative;
  margin-bottom: 10px;
}

.prompt-type-textarea .control-icon ion-badge {
  position: absolute;
  bottom: -3px;
  right: -3px;
  padding: 0px;
  line-height: 1.2em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 2em;
}

.prompt-type-textarea .control-icon-camera ion-badge {
  bottom: 0;
}

.prompt-type-textarea ion-textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 7px;
  margin-top: 0;
}
