.inspection-notes {
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

.inspection-notes p {
  color: var(--ion-color-medium);
  margin: 0;
  margin-top: 5px;
}

:root {
  --ion-color-servus: #50b69a;
  --ion-color-servus-rgb: 80, 182, 154;
  --ion-color-servus-contrast: #eeeeee;
  --ion-color-servus-contrast-rgb: 238, 238, 238;
  --ion-color-servus-shade: #46a088;
  --ion-color-servus-tint: #62bda4;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --shaded-background-color: #f4f5f8;
  --shaded-background-color-rgb: 96, 96, 97;

  --ion-toolbar-background: #ffffff;
  --ion-toolbar-background-rgb: 255, 255, 255;
  --ion-tab-bar-background: #ffffff;
  --ion-tab-bar-background-rgb: 255, 255, 255;

  --ion-item-background: #ffffff;

  --ion-color-uploaded: #3880ff;
  --ion-color-uploaded-rgb: 56, 128, 255;
  --ion-color-uploaded-contrast: #ffffff;
  --ion-color-uploaded-contrast-rgb: 255, 255, 255;
  --ion-color-uploaded-shade: #3171e0;
  --ion-color-uploaded-tint: #4c8dff;
}

.ion-color-servus {
  --ion-color-base: var(--ion-color-servus);
  --ion-color-base-rgb: var(--ion-color-servus-rgb);
  --ion-color-contrast: var(--ion-color-servus-contrast);
  --ion-color-contrast-rgb: var(--ion-color-servus-contrast-rgb);
  --ion-color-shade: var(--ion-color-servus-shade);
  --ion-color-tint: var(--ion-color-servus-tint);
}

.ion-color-uploaded {
  --ion-color-base: var(--ion-color-uploaded);
  --ion-color-base-rgb: var(--ion-color-uploaded-rgb);
  --ion-color-contrast: var(--ion-color-uploaded-contrast);
  --ion-color-contrast-rgb: var(--ion-color-uploaded-contrast-rgb);
  --ion-color-shade: var(--ion-color-uploaded-shade);
  --ion-color-tint: var(--ion-color-uploaded-tint);
}

/* Temporary */
:root {
  --ion-color-primary: #50b69a;
  --ion-color-primary-rgb: 80, 182, 154;
  --ion-color-primary-contrast: #eeeeee;
  --ion-color-primary-contrast-rgb: 238, 238, 238;
  --ion-color-primary-shade: #46a088;
  --ion-color-primary-tint: #62bda4;
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.vertical-align-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.vertical-align-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

/* .list-ios.list-inset, .list-md.list-inset {
  border-radius: 10px;
}

.list-ios.list-inset.list-lines-none ion-item, .list-md.list-inset.line-lines-none ion-item {
  --border-width: 0;
} */
ion-list > ion-list-header {
  margin-bottom: 2px;
}

/* When an item is inside an inset list, the lines=none does not seem to work. This fixes it */
ion-item.item-lines-none {
  --border-width: 0 !important;
}

.list-md.list-inset ion-item:first-child::part(native) {
  border-bottom: 0px; /* Fix for what seems like a bug of an extra border */
}

.ion-page.shaded ion-content {
  --background: var(--shaded-background-color);
}

.ion-page.page-inspection ion-content {
  --background: var(--shaded-background-color);
}

.ion-page.page-inspection .inspection-details {
  --background: inherit;
  padding: 12px 0 4px 0;
}

.ion-page.page-inspection .inspection-details ion-item {
  --background: inherit;
}

.ion-page.page-inspection .inspection-details dl {
  margin: 0;
}

.ion-page.page-inspection .inspection-details dt {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: var(--ion-color-medium-shade);
}

.ion-page.page-inspection .inspection-details dd {
  margin-left: 0;
  margin-inline-start: 0;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--ion-color-dark);
}

.ion-page.page-new-inspection ion-footer ion-toolbar {
  padding-top: 16px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 16px;
}

.ion-page.page-new-inspection ion-label.checkable ion-icon {
  margin-right: 8px;
  position: relative;
  top: 3px;
}

div.description {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-bottom: 8px;
}

div.description p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

div.description ul {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

ion-list.list-inset ion-list-header:first-of-type ion-label,
ion-list.list-inset ion-list-header:first-of-type ion-button {
  margin-top: 10px;
}

.prompt-title {
  font-size: 1rem;
  font-weight: 700;
}

.prompt-wrapper-menu {
  --width: auto;
  --max-width: 80%;
}

.photo-with-status {
  position: relative;
}

.photo-with-status ion-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.required {
  color: #ff0000;
}
