.prompt-type-updown {
  padding: 4px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.prompt-type-group__prompts .list-ios.list-inset {
  margin-left: 0;
  margin-right: 0;
}

.prompt-type-updown .list-ios.list-inset {
  margin-top: 8px;
  margin-bottom: 8px;
}

.prompt-type-group__prompts .prompt-type-updown {
  padding: 0;
  font-size: 12px;
  gap: 4px;
}

.prompt-type-updown__icon {
  --ion-color-medium: rgb(0, 0, 0, 0.2);
  --padding-start: 0;
  --padding-end: 0;
  margin: 0;
  height: auto;
}

.prompt-type-updown__note-present .prompt-type-updown__note-icon {
  --ion-color-medium: var(--ion-color-success);
}

.prompt-type-updown__note-required .prompt-type-updown__note-icon {
  --ion-color-medium: var(--ion-color-danger);
}

.prompt-type-updown__icon ion-icon {
  padding: 8px;
}

.prompt-type-updown__prompt-area {
  flex-grow: 1;
  margin-top: 5px;
}

.prompt-type-updown__prompt-label {
  font-size: 1rem;
  margin-top: 3px;
}

.prompt-type-group__prompts .prompt-type-updown__prompt-label {
  font-size: 0.8rem;
}

.prompt-type-updown ion-textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 7px;
  margin-top: 12px;
}

.prompt-type-updown__note-required ion-textarea {
  border: 1px solid var(--ion-color-danger);
}
