.login-page {
  --background: #ffffff;
  text-align: center;
}

.login-page .login-container {
  min-width: 260px;
  max-width: 500px;
  height: 100vh;
  margin: 0 auto;
  padding: var(--ion-padding, 16px);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 4fr 6fr;
  row-gap: 2em;
}

.login-header {
  align-self: end;
}

.login-page .error {
  border-radius: 5px;
  border: 1px solid var(--ion-color-danger);
  background: var(--ion-color-danger-tint);
  padding: var(--ion-padding, 16px);
  margin-bottom: var(--ion-padding, 16px);
  color: #fff;
}

.login-page .login-container-image,
.login-page .login-container-logo {
  display: block;
}

.login-page .login-container-image {
  max-height: 160px;
  margin-bottom: 20px;
}

.login-page .login-container-image::part(image) {
  max-height: 160px;
}

.login-page label {
  color: var(--ion-color-medium);
  text-align: left;
  display: block;
  font-size: 1em;
  margin: 0px 2px 1rem 2px;
}

.login-page input {
  color: var(--ion-color-medium-shade);
  display: block;
  width: 100%;
  margin-top: 5px;
  border: 1px solid var(--ion-color-medium);
  border-radius: 10px;
  line-height: 2em;
  padding: 5px 7px;
}

.login-page input:focus-visible {
  outline-color: var(--ion-color-servus);
}

.login-page ion-button.button-solid {
  --background: #37987d;
  --background-activated: #37987d;
  --background-focused: #37987d;
  --background-hover: #37987d;
  margin-top: 1rem;
}

.login-page .or-divider {
  font-size: 0.8rem;
  opacity: 0.5;
  margin: 2rem 0;
}
