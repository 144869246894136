.all-none-selector {
  text-align: center;
  margin-bottom: 12px;
}

.none-found {
  padding-left: var(--ion-padding);
  padding-right: var(--ion-padding);
  color: var(--ion-color-medium);
}
