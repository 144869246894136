.signature-item {
  display: block;
  margin-right: 8px;
  width: 100%;
}

.stacked-label {
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 14px;
}

.signature-bounds {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 5px;
}

.signature-canvas {
  display: block;
}

.signature-clear-button {
  margin-top: 16px;
}
