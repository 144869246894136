.floorplan-wrap {
  display: block;
  position: relative;
}

.none-found-toggle {
  padding-left: 0;
}

.prompt-type-punchlist ion-list ion-thumbnail {
  width: 100px;
  height: 100px;
}

.prompt-type-unknown {
  padding: 20px;
}

.punchlist-item-photos ion-icon.remove-photo {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 99px;
  border: 1px solid #fff;
  background: #fff;
}

.punchlist-item-photos ion-button {
  --padding-start: 0;
}

.prompt-type-punchlist .punchlist-item ion-thumbnail {
  position: relative;
}

.prompt-type-punchlist .punchlist-item ion-thumbnail ion-badge {
  position: absolute;
  padding: 0;
  bottom: -4px;
  right: -4px;
  line-height: 20px;
  width: 26px;
  height: 26px;
  border-radius: 99px;
  border: 3px solid #fff;
}

.prompt-type-punchlist ion-button {
  --padding-start: 0;
}

.prompt-type-punchlist ion-toggle {
  height: 21px;
  width: 36px;
  --handle-width: 17px;
}
