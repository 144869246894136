.prompt-type-group__inner {
  padding: 0 16px 12px;
}

.prompt-type-group__prompts {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  margin: 16px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.prompt-type-group__prompts .prompt-type-group {
  padding: 0;
}

.prompt-type-group__prompts .prompt-type-group__prompts {
  border: none;
  border-left: 3px solid rgba(0, 0, 0, 0.05);
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.prompt-type-group__prompts .prompt-type-group__prompts ion-list-header {
  padding-left: 10px;
}

.prompt-type-group__title {
  font-size: 1rem;
  margin: 0 0 1rem 0;
}

.prompt-type-group__repeater-label ion-textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 7px;
  margin-top: 0;
}

.prompt-type-group__repeater-header {
  padding: 8px 16px;
}

.prompt-type-group__repeater-label-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.prompt-type-group__repeater-label-title {
  flex-grow: 1;
  line-height: 24px;
  font-weight: 700;
}

.prompt-type-group__repeater-controls {
  font-size: 24px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
}
