.empty-state-container {
  text-align: center;
}

.empty-state-container strong {
  font-size: 20px;
  line-height: 26px;
}

.empty-state-container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.empty-state-container a {
  text-decoration: none;
}
